import React from "react";

function ViewMoreArticlesFunc({viewMoreArticles}){
    return(
        <div className="w-full bg-gray-200 py-8 px-16">
        <h2 className="text-xl">Fler nyheter</h2>
        <ul className="mt-4">
          {viewMoreArticles.length > 0 &&
            viewMoreArticles.map((article) => (
              <li
                className="list-none mt-4 pb-4 border-b border-gray-200"
                key={article.url}
              >
                <a className="flex items-center" href={article.url}>
                  <div className="ml-4">
                    <h4>{article.title}</h4>
                    <p className="text-sm text-gray-600 mt-1">
                      ({article.date})
                    </p>
                    {article.elements.map((elem) =>
                      elem.nodename === "P" ? (
                        <p key={elem.id}>{elem.value}</p>
                      ) : null
                    )}
                  </div>
                </a>
                <span className="flex items-center ml-auto border-solid border-gray-500 border w-4 h-4 transform rotate-45"></span>
              </li>
            ))}
        </ul>
      </div>
    )
}

export default ViewMoreArticlesFunc