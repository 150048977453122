
import { Routes, Route } from "react-router-dom";

import Header from "./header"
import Footer from './footer'

import News from "./news"
import Article from './article'
import Pages from './pages'

function Section({ path, data, sectionText }) {
    const createViewMoreArticles = (articles, article) => {
        const viewMoreArticles = [];
    
        for (let i = 0; i < articles.length; i++) {
          if (viewMoreArticles.length <= 3) {
            if (articles[i].title !== article.title) {
              viewMoreArticles.push(articles[i]);
            }
          }
        }
    
        return viewMoreArticles;
      };
    
    const homeCategory = 
    data?.categories?.find(category => category.name === 'Hem')?.content;
    
  return (
    <>
      <Header path={path} sectionText={sectionText} data={data} />
      <Routes>
        
        <Route
          exact
          path="/"
          element={<Pages title='Hem' content={homeCategory}/>}
        />
        <Route
          exact
          path="/nyheter"
          element={<News data={data.categories} path={path}/>}
        />
        {data.categories &&
          data.categories.map((c) => {
            if (c.name === "Nyheter") {
              return c.items.map((i) => (
                <Route
                  key={i.link}
                  exact
                  path={`/nyheter/${i.link}`}
                  element={
                    <Article
                      title={i.name}
                      content={i.content}
                      viewMoreArticles={createViewMoreArticles(c.items, i)}
                    />
                  }
                />
              ));
            } else if (c.name === "Sidor") {
              return c.items.map((i) => (
                <Route
                  key={i.link}
                  exact
                  path={`/${i.link}`}
                  element={<Pages title={i.name} content={i.content} />}
                />
              ));
            }
            return null;
          })}
      </Routes>
      <Footer/>
    </>
  );
}

export default Section;
