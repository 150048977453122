function Pages ({ title, content }){
    return(
        <section>
            <div className="p-8 min-h-[50vh] w-fit mx-auto lg:pb-16 lg:px-48 portrait:min-h-[57vh]">
                <h2 className="text-2xl font-bold lg:text-4xl">{title}</h2>
                <div className="mt-5" dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </section>
    )
}

export default Pages