import React, {useState, useEffect} from 'react'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Section from './components/section';

function App() {
  const [i19ifData, setI19ifData] = useState('')
  const [boxningData, setBoxningData] = useState("");
  const [olData, setOlData] = useState("")
  const [skidorData, setSkidorData] = useState("")
  const [skidskytteData, setSkidskytteData] = useState("")

  useEffect(() => {
    const getWebsite = async () => {
      const i19ifFromServer = await fetchWebsite('649318123f998c5e6198af25');
      const boxningFromServer = await fetchWebsite('649311de3f998c5e6198af23');
      const olFromServer = await fetchWebsite('649751359b3c21bc7c41b53f')
      const skidorFromServer = await fetchWebsite('6497515e9b3c21bc7c41b540')
      const skidskytteFromServer = await fetchWebsite('6497517b9b3c21bc7c41b541')

      setI19ifData(i19ifFromServer)
      setBoxningData(boxningFromServer);
      setOlData(olFromServer)
      setSkidorData(skidorFromServer)
      setSkidskytteData(skidskytteFromServer)
    };

    getWebsite();
  }, []);

  const fetchWebsite = async (id) => {
    const res = await fetch(
      `https://api.vbwebb.se/api/websites/${id}`
    );
    const data = await res.json();
    return data;
  };

  return (
    <Router>
      <Routes>
        <Route 
          exact
          path='/*'
          element={<Section path='' sectionText='I19 IF' data={i19ifData}/>}
        />
        <Route 
          exact
          path='/boxning/*'
          element={<Section path='/boxning' sectionText='I19 IF Boxning' data={boxningData}/>}
        />
        <Route 
          exact
          path='/ol-ol-skytte/*'
          element={<Section path='/ol-ol-skytte' sectionText='I19 IF OL/OL-Skytte' data={olData}/>}
        />
        <Route 
          exact
          path='/skidor/*'
          element={<Section path='/skidor' sectionText='I19 IF Skidor' data={skidorData}/>}
        />
        <Route 
          exact
          path='/skidskytte/*'
          element={<Section path='/skidskytte' sectionText='I19 IF Skidskytte' data={skidskytteData}/>}
        />
      </Routes>
    </Router>
  );
}

export default App;
