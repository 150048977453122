import { Link } from 'react-router-dom';

function News({ data, path }) {
  const nyheter = data?.find((category) => category.name === "Nyheter")?.items || [];

  return (
    <section className="p-8 min-h-[50vh] w-fit mx-auto lg:pb-16 lg:px-48 portrait:min-h-[57vh]">
      <h2 className="text-3xl font-bold mb-4">Egna Nyheter</h2>
      <div className="nyheter-container">
        {nyheter.map((nyhet) => (
          <div key={nyhet.name} className="nyhet border-t border-gray-300 my-4 py-2">
            <p className="text-gray-600 text-sm italic mb-2">
              {nyhet.date}
            </p>
            <h3 className="font-bold">{nyhet.name}</h3>
            <Link
              to={`${path}/nyheter/${nyhet.link}`}
              className="läs-mer-länk text-sm text-gray-600"
            >
              Läs mer..
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default News;