import React, { useState } from "react";

import { Link } from "react-router-dom";

import i19ifshield from "../media/I19vapen.png";

function Header({ path, sectionText, data }) {
  const pages =
    data?.categories?.find((category) => category.name === "Sidor")?.items ||
    [];
  const links =
    data?.categories?.find((category) => category.name === "Länkar")?.items ||
    [];

  const [isNavVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible((prevState) => !prevState);
  };

  return (
    <header>
      <div className="flex justify-between items-center p-8 bg-primary shadow-md shadow-neutral-600/75 md:border-b-2 md:border-neutral-200c sm:shadow-neutral-400/25">
        <div className="flex gap-3 items-center">
          <img className="w-1/6 md:w-[10%] lg:w-1/12" src={i19ifshield} alt="i19if.jpg" />
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-neutral-200">{sectionText}</h1>
        </div>

        <nav className="hidden flex-col justify-center items-center gap-5 md:flex-row md:mt-0 md:shadow-none md:flex">
          <Link className="text-neutral-200" to="/">
            Föreningen
          </Link>
          <Link className="text-neutral-200" to="/boxning">
            Boxning
          </Link>
          <Link className="text-neutral-200" to="/ol-ol-skytte">
            OL/OL-Skytte
          </Link>
          <Link className="text-neutral-200" to="/skidor">
            Skidor
          </Link>
          <Link className="text-neutral-200" to="/skidskytte">
            Skidskytte
          </Link>
        </nav>

        <div
          className="space-y-2 md:hidden"
          onClick={toggleNav}
        >
          <div className="w-8 h-0.5 bg-neutral-200"></div>
          <div className="w-8 h-0.5 bg-neutral-200"></div>
          <div className="w-8 h-0.5 bg-neutral-200"></div>
        </div>
      </div>

      

      <div className={`flex gap-4 flex-col fixed right-0 top-0 bg-primary shadow-md shadow-neutral-600/75 h-screen w-[50vw] items-center p-5 transition duration-500 ease-in-out
            ${isNavVisible ? "translate-x-0" : "translate-x-full"}
            md:block md:p-0 md:bg-transparent md:h-fit md:static md:w-full md:transform-none`}>
        <p onClick={toggleNav} className="text-neutral-200 ml-auto mr-6 mt-4 text-3xl md:hidden">&#xd7;</p>

        <nav className="flex flex-col justify-center items-center gap-5 bg-primary py-4 border-b border-neutral-200 md:flex-row md:mt-0 md:shadow-none md:hidden">
          <Link className="text-neutral-200" to="/">
            Föreningen
          </Link>
          <Link className="text-neutral-200" to="/boxning">
            Boxning
          </Link>
          <Link className="text-neutral-200" to="/ol-ol-skytte">
            OL/OL-Skytte
          </Link>
          <Link className="text-neutral-200" to="/skidor">
            Skidor
          </Link>
          <Link className="text-neutral-200" to="/skidskytte">
            Skidskytte
          </Link>
        </nav>

        <nav className="flex flex-col text-neutral-200 justify-center items-center gap-5 p-4 md:flex-row md:text-neutral-900">
          <Link to={`${path}/`}>Hem</Link>
          <Link to={`${path}/nyheter`}>Nyheter</Link>

          {pages.map((item) => (
            <Link to={`${path}/${item.link}`} key={item.name}>
              {item.name}
            </Link>
          ))}

          {links.map((item) => (
            <Link to={item.link} key={item.name}>
              {item.name}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
