import React from 'react';

function Footer() {
  return (
    <footer className="bg-primary text-white p-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-center">
        <div className="mb-6 md:mb-0 md:mr-6">
          <h4 className="text-lg font-semibold mb-2">Postadress:</h4>
          <p className="mb-1">I19 IF</p>
          <p className="mb-1">Lars Rova, Box 9105</p>
          <p className="mb-1">96119 Boden</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-2">Kontakt</h4>
          <p className="mb-1">Tel: 0921-348000</p>
          <p className="mb-1">
            E-post:{" "}
            <a href="mailto: lars.rova@mil.se" className="text-white">
              lars.rova@mil.se
            </a>
          </p>
        </div>
      </div>

      <div className="border-t border-white mt-6 py-4">
        <div className="container mx-auto text-center">
          <p className="mb-4">2024 &copy; I19 If</p>
          <p className="mb-0">
            Hemsidan skapad av{" "}
            <a href="https://vbwebb.se" className="text-white underline">
              Victor Berglund
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
