import React from "react";

import ViewMoreArticlesFunc from "./viewMoreArticles";

const RichTextOutput = ({ title, content, viewMoreArticles }) => {
  return (
    <section>
        <div className="p-8 min-h-[40vh] w-fit mx-auto lg:pb-16 lg:px-48 portrait:min-h-[47vh]">
            <h2 className="text-2xl font-bold mb-3">{title}</h2>
            <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>

        <ViewMoreArticlesFunc viewMoreArticles={viewMoreArticles}/>
    </section>
  );
};

export default RichTextOutput;